import React from 'react'
import Spinner from './Spinner'
import firebase from 'firebase'
import { IoMdArrowBack } from 'react-icons/io'
import { ImCross } from 'react-icons/im'
import { MdClose, MdAdd } from 'react-icons/md'
import { Modal, Button } from 'react-bootstrap'

class EditProject extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isFetching: true,
      isUploading: false,
      data: null,
      gallery: [],
      newPhotos: [],
      showModal: false,
      selectedPhoto: null
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    this.props.db.collection("projects").doc(this.props.projectId).get().then(doc => {
      this.setState({ isFetching: false, data: doc.data(), gallery: doc.data().images })
    })
  }

  handleChange = (evt) => {
    const value = evt.target.value
    this.setState({
      ...this.state,
      data: { ...this.state.data, [evt.target.name]: value },
    })
  }

  handleCheck = () => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, isShown: !this.state.data.isShown }
    })
  }

  handleCharacteristic = (evt) => {
    const value = evt.target.value
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        characteristics: {
          ...this.state.data.characteristics,
          [evt.target.name]: value
        }
      }
    })
  }

  handleCharName = (evt) => {
    this.setState({
      ...this.state,
      charName: evt.target.value
    })
  }

  handleCharValue = (evt) => {
    this.setState({
      ...this.state,
      charValue: evt.target.value,
    })
  }

  handleAddCharacteristic = () => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        characteristics: {
          ...this.state.data.characteristics,
          [this.state.charName]: this.state.charValue
        }
      }
    })

    this.handleClearCharacteristics()
  }

  handleDeleteCharacteristic = () => {
    const state = {...this.state}
    delete state.data.characteristics[this.state.charName]

    this.setState(state)

    this.handleClearCharacteristics()
  }

  handleClearCharacteristics = () => {
    this.setState({
      charName: "",
      charValue: ""
    })
  }

  handleChangeCharacteristic = (evt) => {
    this.setState({
      charName: evt.target.value,
      charValue: this.state.data.characteristics[evt.target.value] ? this.state.data.characteristics[evt.target.value] : ''
    })
  }

  handleSubmit = (evt) => {
    evt.preventDefault()

    this.setState({
      isUploading: true
    })

    new Promise((resolve) => {
      //prefiltrujem vsetky URLs pre pripad ze uzivatel vymazal niektoru z fotiek ktore boli predtym v projekte
      this.setState({
        data: { ...this.state.data, images: this.state.data.images.filter(photo => this.state.gallery.includes(photo)) }
      })

      //ak uzivatel nepridal ziadne fotky
      if (!this.state.newPhotos.length) resolve()

      var uploaded = 0

      this.state.newPhotos.forEach(photo => {
        var storageRef = firebase.storage().ref(photo.name)
        storageRef.put(photo).then(snapshot => {
          snapshot.ref.getDownloadURL().then(url => {
            uploaded++
            document.getElementById("projectUploadProgress").style.width = ((uploaded + 1) / this.state.newPhotos.length) * 100 + "%"
            this.setState({
              ...this.state,
              data: { ...this.state.data, images: [...this.state.data.images, url] },
            })
            if (uploaded === this.state.newPhotos.length) resolve()
          })
        })
      })
    }).then(() => {
      this.props.db.collection("projects").doc(this.props.projectId).set(this.state.data).then(() => {
        this.setState({
          isUploading: false
        })

        this.props.navigator("projects")
      })
    })
  }

  selectFile = (evt) => {
    evt.preventDefault()
    this.upload.click()
  }

  onFileChange = (evt) => {
    evt.preventDefault()
    var file = evt.target.files[0]

    this.setState({
      ...this.state,
      gallery: [...this.state.gallery, URL.createObjectURL(file)],
      newPhotos: [...this.state.newPhotos, file]
    })
  }

  deletePhoto = (toBeDeleted) => {
    this.setState({
      gallery: this.state.gallery.filter(photo => photo !== toBeDeleted),
    })
  }

  selectPhoto = (selectedPhoto) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        sliderPhoto: selectedPhoto
      }
    })
  }

  deleteProject = () => {
    this.props.db.collection("projects").doc(this.props.projectId).delete().then(() => {
      this.props.db.collection("counts").doc("projects").get().then(actualCount => {
        this.props.db.collection("counts").doc("projects").set({ count: actualCount.data().count - 1 }).then(() => {
          this.handleClose()
          this.props.navigator('projects')
        })
      })
    })
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      showModal: false
    })
  }

  handleShow = () => {
    this.setState({
      ...this.state,
      showModal: true
    })
  }

  render() {
    return (
      <>
        <h2 className="tabHeader">Projekty</h2>
        {!this.state.isUploading ?
          <div className="editProject">
            <span className="backButton" onClick={() => this.props.navigator('projects')}><IoMdArrowBack /> Späť na všetky projekty</span>
            <span className="deleteProjectBtn" onClick={this.handleShow}>Odstrániť projekt</span><br />
            {this.state.isFetching ? <Spinner /> :
              <form onSubmit={this.handleSubmit}>
                <input type="text" placeholder="Názov projektu" name="name" value={this.state.data.name} onChange={this.handleChange} autoComplete="off" />
                <textarea value={this.state.data.contentPreview} placeholder="Krátky popis projektu" name="contentPreview" onChange={this.handleChange} autoComplete="off" />
                <textarea value={this.state.data.content} placeholder="Popis projektu" name="content" onChange={this.handleChange} autoComplete="off" />
                <input type="number" name="year" placeholder="Rok v ktorom bol projekt realizovaný" value={this.state.data.year} onChange={this.handleChange} autoComplete="off" />
                
                <input type="text" placeholder="Názov charakteristky" name="charName" onChange={this.handleCharName} value={this.state.charName} autoComplete="off" />
                <input type="text" placeholder="Hodnota charakteristiky" name="charValue" onChange={this.handleCharValue} value={this.state.charValue} autoComplete="off" />
                <div className="charWrapper">
                  <select name="charSelect" placeholder="Charakteristika" onChange={this.handleChangeCharacteristic}>
                    <option defaultValue></option>
                    {Object.keys(this.state.data.characteristics).map(characteristic => <option key={characteristic} value={characteristic}>{characteristic}</option>)}
                  </select>

                  <span className="newChar" onClick={this.handleAddCharacteristic}><MdAdd /></span>
                  <span className="deleteChar" onClick={this.handleDeleteCharacteristic}><MdClose /></span>
                </div>
                
                <span className="isShownLabel">Zobrazovať projekt v slideri</span>
                <input type="checkbox" name="isShown" checked={this.state.data.isShown} onChange={this.handleCheck} />

                <div className="projectPhotos">
                  {this.state.gallery.map(photo =>
                    <div className="projectPhotoContainer" key={photo}>
                      <img src={photo} key={photo} alt="projectPhoto" className={this.state.data.sliderPhoto === photo ? 'photo selected' : 'photo'} onClick={() => this.selectPhoto(photo)} />
                      <span className="deleteButton" onClick={() => this.deletePhoto(photo)}><ImCross/></span>
                    </div>
                  )}
                </div>

                <input type="file" ref={(ref) => this.upload = ref} style={{ display: "none" }} onChange={this.onFileChange} />
                <button onClick={this.selectFile} className="photoUploadBtn">Nahrať fotku</button>
                <input type="submit" value="Uložiť" />
              </form>
            }
          </div>
          :
          <div className="uploadingProject">
            <Spinner />
            <p>Nahrávam projekt do databázy...</p>
            <div className="progress">
              <div className="progress-bar" id="projectUploadProgress" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        }
        {this.state.showModal && <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Odstrániť projekt</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Naozaj chcete odstrániť projekt? Túto akciu nie je možné vrátiť.</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>Zatvoriť</Button>
            <Button variant="danger" onClick={this.deleteProject}>Odstrániť</Button>
          </Modal.Footer>
        </Modal.Dialog>}
      </>
    )
  }
}

export default EditProject