import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import DashboardMenu from './DashboardMenu'
import ProjectDashboard from './ProjectDashboard'
import logo from '../../assets/logo.png'
import EditProject from './EditProject'
import NewProject from './NewProject'
import MessagesDashboard from './MessagesDashboard'
import ContentManager from './ContentManager'

import firebase from 'firebase/app'
import MessageDetail from './MessageDetail'

class Dashboard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activeScreen: 'projects',
            activeProjectId: null,
            activeMessageId: null,
            newMessages: 0
        }
    }
    
    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.props.db.collection("counts").doc("unreadMessages").get().then(unreadMessages => {
            this.setState({
                newMessages: unreadMessages.data().count
            })
        })
    }

    setNewMessages = (unreadMessagesCount) => {
        this.setState({newMessages: unreadMessagesCount})
    }

    changeScreen = (screen, {projectId = null, messageId = null} = {}) => {
        this.setState({
            activeScreen: screen,
            activeProjectId: projectId,
            activeMessageId: messageId
        })
    }

    render() {
        return (
            <Container className="dashboardContainer">
                <Row>
                    <Col className="dashboardHeader">
                        <img src={logo} alt="logo" className="dashboardLogo" onClick={() => firebase.auth().signOut()}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="dashboardMenu" md={3}>
                        <DashboardMenu changeScreen={this.changeScreen} newMessages={this.state.newMessages}/>
                    </Col>
                    <Col className="dashboardContent" md={9}>
                        {this.state.activeScreen === 'projects' && <ProjectDashboard db={this.props.db} navigator={this.changeScreen}/>}
                        {this.state.activeScreen === 'messages' && <MessagesDashboard db={this.props.db} navigator={this.changeScreen} changeMessagesNumber={this.setNewMessages}/>}
                        {this.state.activeScreen === 'edit' && <EditProject db={this.props.db} projectId={this.state.activeProjectId} navigator={this.changeScreen}/>}
                        {this.state.activeScreen === 'new' && <NewProject db={this.props.db} navigator={this.changeScreen}/>}
                        {this.state.activeScreen === 'messageDetail' && <MessageDetail db={this.props.db} navigator={this.changeScreen} messageId={this.state.activeMessageId} changeMessagesNumber={this.setNewMessages}/>}
                        {this.state.activeScreen === 'content' && <ContentManager db={this.props.db} navigator={this.changeScreen}/>}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Dashboard