import React from 'react'
import { Element } from 'react-scroll'
import { Row, Col, Container } from 'react-bootstrap'
import { MapWithAMarker } from './MapContainer'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { IoMdMail, } from 'react-icons/io'
import { IoLocationSharp } from 'react-icons/io5'
import ContactForm from './ContactForm'
import firebase from 'firebase'

class Contact extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      contactText: ''
    }
  }

  componentDidMount() {
    firebase.firestore().collection("content").doc("contact").get().then(doc => {
      this.setState({
        contactText: doc.data().content
      })
    })
  }

  render() {
    return (
      <Element name="contact" id="contact">
        <div className="contact">
          <Container>
            <Row>
              <Col xs={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} md={{ span: 12, order: 3 }} lg={{ span: 7, order: 1 }} className="map">
                <MapWithAMarker
                  googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + process.env.REACT_APP_MAPSAPIKEY + "&v=3.exp&libraries=geometry,drawing,places"}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `550px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </Col>
              <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 5, order: 2 }} className="contact-right">
                <div className="contact-text">
                  <h2><b>Kontaktujte</b> nás</h2>
                  <p>Máte záujem o vypracovanie cenovej ponuky, alebo sa chcete informovať ohľadom našich služieb? Neváhajte nás kontaktovať.</p>
                </div>
                <ContactForm />
              </Col>
              <Col sm={{ span: 12, order: 2 }} xs={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }} className="contact-icons">
                <div className="contact-line">
                  <IoLocationSharp size={30} color={"#2b599c"} />
                  <span>Adresa kancelárie:<br /><b>Červeného Kríža 64<br />029 01 Námestovo</b></span>
                </div>
                <div className="contact-line">
                  <BsFillTelephoneFill size={25} color={"#2b599c"} />
                  <span><a href="tel:+905426388">+421 905 426 388</a></span>
                </div>
                <div className="contact-line">
                  <IoMdMail size={28} color={"#2b599c"} />
                  <span><a href="mailto:bedlek@bedlek.sk">bedlek@bedlek.sk</a></span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Element>
    )
  }
}

export default Contact