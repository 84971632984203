import React from 'react'
import { Badge } from 'react-bootstrap'

const MesssageRow = (props) => {
    return (
        <tr className="messagesRow" onClick={() => props.navigator('messageDetail', {messageId: props.id})}>
            <td>{props.data.name}</td>
            <td>{props.data.mail}</td>
            <td>
                {
                    `${props.data.created_at.toDate().getDate()}.
                    ${props.data.created_at.toDate().getMonth() + 1}.
                    ${props.data.created_at.toDate().getFullYear()}`
                }
            </td>
            <td className="text-center">
                {!props.data.opened && <Badge variant="primary">Neprečítaná</Badge>}
                {props.data.opened && <Badge variant="light">Prečítaná</Badge>}
                </td>
        </tr>
    )
}

export default MesssageRow