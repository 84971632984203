import React from 'react';
import { Link, animateScroll } from 'react-scroll'
import { withRouter, matchPath } from 'react-router-dom'
import { IoMdArrowBack } from 'react-icons/io'
import { Link as RouterLink } from 'react-router-dom'

class Menu extends React.Component {
    componentDidUpdate() {
        if (this.props.location.state && (this.props.location.state.lastPath === 'project' || this.props.location.state.lastPath === 'allProjects') &&
        this.props.location.pathname === '/') animateScroll.scrollTo(document.getElementById('projects').offsetTop)
    }

    render() {
        return (
            <ul className="header-nav">
                {this.props.location.pathname === "/" ?
                    <>
                        <Link to="uvod" smooth={true} offset={-100} duration={700}><li>Úvod</li></Link>
                        <Link to="aboutus" smooth={true} offset={-80} duration={700}><li>O nás</li></Link>
                        <Link to="projects" smooth={true} offset={-100} duration={700}><li>Projekty</li></Link>
                        <Link to="contact" smooth={true} offset={-50} duration={700}><li className="highlighted">Kontakt</li></Link>
                    </>
                    :
                    (this.props.location.state && this.props.location.state.lastPath === "allProjects") && <span className="goBack" onClick={() => this.props.history.push('/projekty')}><IoMdArrowBack size={25} /> Späť na všetky projekty</span>}
                {(this.props.location.state && matchPath(this.props.location.pathname, {path: '/projekty/:id'}) && this.props.location.state.lastPath === "home") &&
                    <RouterLink to={{ pathname: '/', state: { lastPath: 'project' } }}>
                        <span className="goBack" ><IoMdArrowBack size={25} /> Späť na domov</span>
                    </RouterLink>}
                {(this.props.location.pathname === "/projekty" && this.props.location.state && this.props.location.state.lastPath === "home") ?
                    <RouterLink to={{ pathname: '/', state: { lastPath: 'allProjects' } }}>
                        {console.log("asd")}
                    <span className="goBack" ><IoMdArrowBack size={25} /> Späť na domov</span>
                    </RouterLink>
                    :
                    (!this.props.location.state && this.props.location.pathname !== '/') && <span className="goBack" onClick={() => this.props.history.push('/')}><IoMdArrowBack size={25} /> Späť na domov</span>
                }
            </ul>
        )
    }
}

export default withRouter(Menu)