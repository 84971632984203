import React from 'react'
import { VscArchive, VscInbox, VscOutput } from 'react-icons/vsc'
import { Badge } from 'react-bootstrap'

class DashboardMenu extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: 'projects'
        }
    }

    menuClickHandle = (tabName) => {
        this.setState({
            activeTab: tabName
        })
        this.props.changeScreen(tabName)
    }

    render() {
        return (
            <ul>
                <li onClick={() => this.menuClickHandle('projects')} className={`menuItem ${this.state.activeTab === 'projects' ? 'active' : ""}`}>
                    <VscArchive size={26} />
                        Projekty
                        </li>
                <li onClick={() => this.menuClickHandle('messages')} className={`menuItem ${this.state.activeTab === 'messages' ? 'active' : ""}`}>
                    <VscInbox size={26} />
                        Správy {this.props.newMessages !== 0 && <Badge variant="secondary">{this.props.newMessages}</Badge>}
                    </li>
                    <li onClick={() => this.menuClickHandle('content')} className={`menuItem ${this.state.activeTab === 'content' ? 'active' : ""}`}>
                    <VscOutput size={26} />
                        Obsah
                    </li>
            </ul>
        )
    }
}

export default DashboardMenu