import React from 'react'
import { Element } from 'react-scroll'
import { Row, Col, Container } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import firebase from 'firebase'
import ProjectSlide from './ProjectSlide'
import { WhiteSpinner } from './admin/Spinner'
import { Link as RouterLink } from 'react-router-dom'

class Projects extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      projects: [],
      isFetching: false,
      text: ''
    }
  }

  componentDidMount() {
    if (!this.state.projects.length) {
      this.setState({ ...this.state, isFetching: true })
      this.fetchProjects()
      this.fetchText()
    }


  }

  fetchText = () => {
    firebase.firestore().collection("content").doc("realisedProjects").get().then(doc => {
      this.setState({
        text: doc.data().content
      })
    })
  }

  fetchProjects = () => {
    firebase.firestore().collection("projects").get().then(docs => {

      docs.forEach(project => {
        this.setState({
          ...this.state,
          projects: [...this.state.projects, { id: project.id, data: project.data() }]
        })
      })

      this.setState({ ...this.state, isFetching: false })
    })
  }

  render() {
    return (
      <Element name="projects" id="projects">
        <div className="projects">
          <Container>
            <Row>
              <Col>
                <h2>Realizované <b>projekty</b></h2>
                <h4>{this.state.text}</h4>
                {this.state.isFetching ? <WhiteSpinner /> :
                  <OwlCarousel nav={true} autoplayHoverPause={true} autoplay={true} loop={true} className="owl-theme" margin={8} autoplayTimeout={5000} autoplaySpeed={700} center={true} responsive={{ 0: { items: 1 }, 992: { items: 3 } }}>
                    {this.state.projects.map(project => project.data.isShown && <ProjectSlide id={project.id} data={project.data} key={project.id} />)}
                  </OwlCarousel>
                }
                <RouterLink to={{ pathname: '/projekty', state: { lastPath: 'home' } }} className="bedlek-btn white">Všetky projekty</RouterLink>
              </Col>
            </Row>
          </Container>
        </div>
      </Element>
    )
  }
}

export default Projects