import React from 'react';
import { Container } from 'react-bootstrap'
import MobileMenu from './MobileMenu'
import logo from '../assets/mobile_logo.png'
import { Link as RouterLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { IoMdArrowBack } from 'react-icons/io'

class MobileHeader extends React.Component {
  darkenContent() {
    if (document.querySelector('#toggle-menu').checked) {
      document.querySelector('#menuBtn').classList.remove("active")
      document.querySelector('#menuBackground').classList.remove("active")
      document.querySelector('#menuBackground').style.pointerEvents = 'all'
    } else {
      document.querySelector('#menuBtn').classList.add("active")
      document.querySelector('#menuBackground').classList.add("active")
      document.querySelector('#menuBackground').style.pointerEvents = 'none'
    }
  }

  render() {
    return (
      <Container className="d-block d-md-none">
        <div className="mobile-header">
          <div id="menu" className="menu">
            <span id="menuBackground"></span>
            <RouterLink to='/' className="mobile-logo"><img src={logo} className="logo" alt="Bedlek.sk" /></RouterLink>
            {this.props.location.pathname === "/" ?
              <>
                <input id="toggle-menu" type="checkbox" />
                <label htmlFor="toggle-menu" id="menuBtn" onClick={this.darkenContent}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </label>
                <MobileMenu close={this.darkenContent} />
              </>
              :
              (this.props.location.state && this.props.location.state.lastPath === "allProjects") && <span className="goBack" onClick={() => this.props.history.push('/projekty')}><IoMdArrowBack size={25} /> Späť na všetky projekty</span>}
            {(this.props.location.state && this.props.location.state.lastPath === "home") && <span className="goBack" onClick={() => this.props.history.push('/')}><IoMdArrowBack size={25} /> Späť na domov</span>}
            {(!this.props.location.state && this.props.location.pathname !== '/') && <span className="goBack" onClick={() => this.props.history.push('/')}><IoMdArrowBack size={25} /> Späť na domov</span>
            }
          </div>
        </div>
      </Container>
    )
  }
}

export default withRouter(MobileHeader)