import React from 'react'
import { Link } from 'react-scroll'

class SideNavigation extends React.Component {
  constructor() {
    super()

    this.state = {
      navOffset: 0,
      welcomeOffset: 0,
      aboutUsOffset: 0,
      projectsOffset: 0,
      contactOffset: 0,
      menuHeight: 0
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll() {
    this.setState({
      aboutUsOffset: document.getElementById("aboutus").getBoundingClientRect().top - this.state.navOffset - this.state.menuHeight / 2,
      projectsOffset: document.getElementById("projects").getBoundingClientRect().top - this.state.navOffset - this.state.menuHeight / 2,
      contactOffset: document.getElementById("contact").getBoundingClientRect().top - this.state.navOffset - this.state.menuHeight / 2,
      welcomeOffset: document.getElementById("welcome").getBoundingClientRect().top - this.state.navOffset - this.state.menuHeight / 2,
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.setState({
      navOffset: document.getElementById("sideNav").getBoundingClientRect().top,
      menuHeight: document.getElementById("headerFixedMenu").offsetHeight,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    return (
      <div className={((this.state.projectsOffset < 0 && this.state.contactOffset > 0) ||
        (this.state.aboutUsOffset >= 0)) ? "white sideNav" : "sideNav"} id="sideNav">
        <Link to="uvod" smooth={true} offset={-100} duration={700}>
          <span className={(this.state.welcomeOffset <= 0 && this.state.aboutUsOffset >= 0) ? "active" : ""}>
            1
          </span>
        </Link>
        <Link to="aboutus" smooth={true} offset={-100} duration={700}>
          <span className={(this.state.aboutUsOffset < 0 && this.state.projectsOffset > 0) ? "active" : ""}>
            2
          </span>
        </Link>
        <Link to="projects" smooth={true} offset={-100} duration={700}>
          <span className={(this.state.projectsOffset < 0 && this.state.contactOffset > 0) ? "active" : ""}>
            3
          </span>
        </Link>
        <Link to="contact" smooth={true} offset={-100} duration={700}>
          <span className={(this.state.contactOffset < 0) ? "active" : ""}>
            4
          </span>
        </Link>
      </div>
    )
  }
}

export default SideNavigation