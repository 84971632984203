import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

export const MapWithAMarker = withScriptjs(withGoogleMap(props =>
    <GoogleMap
        defaultZoom={17}
        defaultCenter={{ lat: 49.4055457, lng: 19.47833521 }}
        options={{styles: mapStyle}}
    >
        <Marker
            position={{ lat: 49.40545, lng: 19.47840 }}
        />
    </GoogleMap>
));

const mapStyle = [
  {
      "featureType": "administrative.country",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "saturation": "9"
          },
          {
              "color": "#ffffff"
          }
      ]
  },
  {
      "featureType": "administrative.province",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#ff0000"
          }
      ]
  },
  {
      "featureType": "administrative.land_parcel",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#ffffff"
          }
      ]
  },
  {
      "featureType": "landscape.man_made",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "gamma": "1.60"
          },
          {
              "lightness": "-2"
          },
          {
              "visibility": "on"
          },
          {
              "weight": "1.77"
          },
          {
              "hue": "#0063ff"
          }
      ]
  },
  {
      "featureType": "landscape.man_made",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#104291"
          },
          {
              "lightness": "-18"
          },
          {
              "gamma": "2.10"
          },
          {
              "saturation": "43"
          },
          {
              "weight": "1.34"
          }
      ]
  },
  {
      "featureType": "landscape.man_made",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "landscape.natural",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#6fe796"
          }
      ]
  },
  {
      "featureType": "landscape.natural.landcover",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "saturation": "61"
          },
          {
              "color": "#6dd68f"
          },
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "landscape.natural.terrain",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "saturation": "100"
          },
          {
              "lightness": "71"
          },
          {
              "gamma": "1.29"
          },
          {
              "color": "#65d88a"
          }
      ]
  },
  {
      "featureType": "landscape.natural.terrain",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "saturation": "-55"
          },
          {
              "color": "#994444"
          },
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#104291"
          },
          {
              "lightness": "61"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "labels.text",
      "stylers": [
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "poi.attraction",
      "elementType": "geometry",
      "stylers": [
          {
              "visibility": "off"
          },
          {
              "color": "#ff0000"
          }
      ]
  },
  {
      "featureType": "poi.attraction",
      "elementType": "labels.text",
      "stylers": [
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "poi.business",
      "elementType": "labels.text",
      "stylers": [
          {
              "visibility": "on"
          }
      ]
  },
  {
      "featureType": "poi.medical",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#97d1b4"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#fcb501"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "labels.text",
      "stylers": [
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#fcb501"
          },
          {
              "saturation": "-5"
          },
          {
              "gamma": "1.50"
          },
          {
              "lightness": "0"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#ffffff"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#000000"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "labels.icon",
      "stylers": [
          {
              "hue": "#ff2e00"
          },
          {
              "saturation": "31"
          },
          {
              "lightness": "-5"
          },
          {
              "gamma": "1.73"
          },
          {
              "weight": "0.01"
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#e5ae19"
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "lightness": "-3"
          },
          {
              "gamma": "2.00"
          },
          {
              "saturation": "7"
          }
      ]
  },
  {
      "featureType": "transit.line",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#000000"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#69c9ea"
          }
      ]
  }
]