import React from 'react'
import logo from '../assets/logo_white.png'

class Footer extends React.Component {
    render() {
        return (
            <>
                <div className="footer">
                    <img src={logo} className="footer-logo" alt="Bedlek.sk" />
                </div>
                <div className="footer-copy">
                    <b>BEDLEK.SK</b> &copy; {new Date().getFullYear()} Všetky práva vyhradené
                </div>
            </>
        )
    }
}

export default Footer