import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

class ProjectSlide extends React.Component {
  render() {
    return (
      <RouterLink to={{ pathname: `/projekty/${this.props.data.slug}`, state: { lastPath: 'home' } }}>
        <div className="slide-container">
          <img src={this.props.data.sliderPhoto ? this.props.data.sliderPhoto : this.props.data.images[0]} className="slider-image"/>
          <span className="slide-text-bg" />
          <div className="text">
            <span className="year">{this.props.data.year}</span>
            <h3>{this.props.data.name}</h3>
          </div>
        </div>
      </RouterLink>
    )
  }
}

export default ProjectSlide