import React from 'react'
import MessageRow from './MessageRow'
import Spinner from './Spinner'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'

class MessagesDashboard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isFetching: false,
            messages: [],
            count: null,
            page: 1,
            per_page: 10,
            total_pages: null
        }
    }

    componentDidMount() {
        this.fetchMessages(this.state.page)
    }

    fetchMessages = (page) => {
        this.setState({
            isFetching: true,
            messages: [],
            page: page
        })
        //najprv ziskam count
        this.props.db.collection("counts").doc("messages").get().then(actualCount => {
            this.setState({
                count: actualCount.data().count,
                total_pages: Math.floor(actualCount.data().count / this.state.per_page)
            })
            //ziskam poslednych 10 sprav
            this.props.db.collection("messages").orderBy("created_at").startAt((page - 1) * this.state.per_page).limit(this.state.per_page).get().then(docs => {
                docs.forEach(doc => {
                    this.setState({
                        messages: [{ id: doc.id, data: doc.data() }, ...this.state.messages]
                    })
                });
                //ziskam unreadcount
                this.props.db.collection("counts").doc("unreadMessages").get().then(unreadCount => {
                    this.props.changeMessagesNumber(unreadCount.data().count)
                })

                this.setState({
                    isFetching: false
                })
            })
        })
    }

    render() {
        return (
            <>
                <h2 className="tabHeader">Správy</h2>
                <div className="messagesTab">
                    {this.state.isFetching ? <Spinner /> :
                        <>
                            {this.state.messages.length ?
                                <>
                                    <table className="dataTable">
                                        <thead>
                                            <tr>
                                                <th>Meno</th>
                                                <th>E-mail</th>
                                                <th>Prijatá</th>
                                                <th className="text-center">Stav</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.messages.map(message =>
                                                <MessageRow data={message.data} id={message.id} navigator={this.props.navigator} key={message.id}/>
                                            )}
                                        </tbody>
                                    </table>
                                    {this.state.count > this.state.messages.length &&
                                        <div className="pagination">
                                            {this.state.page !== 1 && <span className="prevPage" onClick={() => this.fetchMessages(this.state.page - 1)}>
                                                <MdNavigateBefore color="#FFFFFF"/> Predošlé správy
                                                </span>}
                                            <span className="actualPage">{this.state.page}</span>
                                            {this.state.page < this.state.total_pages && <span className="nextPage" onClick={() => this.fetchMessages(this.state.page + 1)}>
                                                Ďalšie správy <MdNavigateNext color="#FFFFFF"/>
                                                </span>}
                                        </div>
                                    }
                                </>
                                :
                                <p>Nenašli sa žiadne správy</p>
                            }
                        </>
                    }
                </div>
            </>
        )
    }
}

export default MessagesDashboard