import React from 'react'
import firebase from 'firebase'
import { Container } from 'react-bootstrap'
import Spinner from './admin/Spinner'

class Document extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            content: "",
            heading: "",
            isFetching: false
        }
    }

    componentDidMount() {
        this.fetchDocument()
    }

    fetchDocument = () => {
        this.setState({
            ...this.state,
            isFetching: true
        })

        firebase.firestore().collection("documents").doc(this.props.id).get().then(document => {
            this.setState({
                ...this.state,
                content: <div dangerouslySetInnerHTML={{ __html: document.data().content }}></div>,
                heading: document.data().heading,
                isFetching: false
            })
        })

    }

    render() {
        return (
            <div id="content">
                <Container className="document">
                    {this.state.isFetching ? <Spinner /> :
                        <>
                            <h2>{this.state.heading}</h2>
                            {this.state.content}
                        </>
                    }
                </Container>
            </div>
        )
    }
}

export default Document