import React from 'react'
import firebase from 'firebase'
import { Container, Row, Col } from 'react-bootstrap'
import Spinner from './admin/Spinner'
import { Link as RouterLink } from 'react-router-dom'

class AllProjects extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isFetching: true,
            projects: []
        }
    }

    componentDidMount() {
        document.title = 'Projekty | BEDLEK.SK'
        this.fetchProjects().then(() => {
            this.setState({
                isFetching: false
            })
        })
    }

    fetchProjects = () => {
        return new Promise(resolve => {
            firebase.firestore().collection("projects").orderBy("created_at").get().then(docs => {
                docs.forEach(project => {
                    this.setState({
                        projects: [...this.state.projects, project.data()]
                    })
                })
                resolve()
            })
        })
    }

    render() {
        return (
            <div id="content">
                <Container className="projectContent">
                    <h1 className="allProjectsHeading">Všetky realizované <b>projekty</b></h1>
                    {this.state.isFetching ? <Spinner /> :
                        <Row>
                            {this.state.projects.map(project =>
                                <Col xs={12} md={4} key={project.slug}>
                                    <RouterLink to={{ pathname: `/projekty/${project.slug}`, state: {lastPath: 'allProjects'}}}>
                                        <div className="projectCard">
                                            <div className="imgContainer">
                                                <img src={project.images[0]} alt={project.name}/>
                                            </div>
                                            <h2>{project.name}</h2>
                                            <p>{project.contentPreview}</p>
                                            <span className="bedlek-btn white showProjectBtn">Zobraziť projekt</span>
                                        </div>
                                    </RouterLink>
                                </Col>)}
                        </Row>
                    }
                </Container>
            </div>
        )
    }
}

export default AllProjects