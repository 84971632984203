import React from 'react'
import Login from './Login'
import Dashboard from './Dashboard'
import '../../styles/admin.scss'
import firebase from 'firebase/app'
import 'firebase/auth'
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from '@react-firebase/auth'

class AdminPanel extends React.Component {
    componentDidMount() {
        document.title = 'Admin | BEDLEK.SK'
    }

    render() {
        return (
            <FirebaseAuthProvider firebase={firebase}>
                <div className="admin">
                    <IfFirebaseUnAuthed>
                        <Login />
                    </IfFirebaseUnAuthed>
                    <IfFirebaseAuthed>
                        <Dashboard db={firebase.firestore()}/>
                    </IfFirebaseAuthed>
                </div>
            </FirebaseAuthProvider>
        )
    }
}

export default AdminPanel