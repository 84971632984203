import React from 'react'
import { IoCheckmarkSharp, IoCloseSharp } from 'react-icons/io5'
import { FiEdit3 } from 'react-icons/fi'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const ProjectRow = (props) => {
    return (
        <tr className="projectRow" onClick={() => props.navigator('edit', {projectId: props.id})}>
            <td>{props.data.name}</td>
            <td>{props.data.isShown ? <IoCheckmarkSharp color={'green'} /> : <IoCloseSharp color={'red'} />}</td>
            <td>
                {
                    `${props.data.created_at.toDate().getDate()}.
                    ${props.data.created_at.toDate().getMonth() + 1}.
                    ${props.data.created_at.toDate().getFullYear()}`
                }
            </td>
            <td className="text-center"><OverlayTrigger placement="right" delay={{ show: 0, hide: 0 }} overlay={
                <Tooltip>Upraviť</Tooltip>
            }><FiEdit3 /></OverlayTrigger></td>
        </tr>
    )
}

export default ProjectRow