import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import vectorImage from '../assets/vector-art1.jpg'
import { Link } from 'react-scroll'
import { Element } from 'react-scroll'
import firebase from 'firebase'

export default function Welcome() {
  useEffect(() => {
    document.title = 'Domov | BEDLEK.SK'
  })

  const [heading, setHeading] = useState('')
  const [headingText, setHeadingText] = useState('')

  useEffect(() => {
    firebase.firestore().collection("content").doc('mainHeading').get().then(doc => {
      setHeading(doc.data().content)
    })

    firebase.firestore().collection("content").doc("mainText").get().then(doc => {
      setHeadingText(doc.data().content)
    })
  }, [])

  return (
    <Element name="uvod" id="welcome">
      <div className="welcome">
        <div className="backgroundImage"><img src={require('../assets/008.jpg')} alt='Bedlek.sk' /></div>
        <div className="projectImage">
          <h1>Bedlek.sk</h1>
          <h2>Stavebná spoločnosť</h2>
          <Link to="aboutus" smooth={true} offset={-80} duration={700}>
            <span className="scrollDown">
              <span></span>
              <span></span>
              Posunúť nadol
            </span>
          </Link>
        </div>
      </div>
    </Element>
  )
}