import React from 'react'
import Spinner from './Spinner'

export default class ContentManager extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isFetching: true,
            isUploading: false,
            data: {}
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.props.db.collection("content").get().then(doc => {
            doc.forEach(doc => {
                this.setState({ isFetching: false, data: { ...this.state.data, [doc.data().name]: doc.data() } })
            })
        })
    }

    handleChange = (evt) => {
        const value = evt.target.value
        this.setState({
            ...this.state,
            data: { ...this.state.data, [evt.target.name]: { ...this.state.data[evt.target.name], content: value } },
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()

        const nItems = Object.keys(this.state.data).length
        let uploaded = 0
        this.setState({ isUploading: true })

        Object.keys(this.state.data).forEach(name => {
            this.props.db.collection("content").doc(this.state.data[name].name).set(this.state.data[name]).then(() => {
                uploaded += 1
                if (uploaded === nItems) { this.setState({ isUploading: false }) }
            })
        })
    }

    render() {
        return (
            <>
                <h2 className="tabHeader">Projekty</h2>
                <div className="editProject">
                    {this.state.isFetching || this.state.isUploading ? <Spinner /> :
                        <form onSubmit={this.handleSubmit}>
                            {Object.keys(this.state.data).map(entry =>
                                <span key={this.state.data[entry].name}>
                                    <h4>{this.state.data[entry].nameFancy}</h4>
                                    <textarea rows={3} value={this.state.data[entry].content} placeholder={this.state.data[entry].nameFancy} name={this.state.data[entry].name} onChange={this.handleChange} autoComplete="off" />
                                </span>
                            )}
                            <input type="submit" value="Uložiť" />
                        </form>
                    }
                </div>
            </>
        )
    }
}