import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Spinner from './Spinner'
import logo from '../../assets/logo.png'
import firebase from 'firebase'

class Login extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mail: "",
            pass: "",
            isFetching: false
        }
    }

    handleInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()

        document.getElementById("loginButton").classList = "disabled"

        this.setState({
            isFetching: true
        })

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
            firebase.auth().signInWithEmailAndPassword(this.state.mail, this.state.pass).then(() => {
                this.setState({
                    isFetching: false
                })
            }).catch((error) => {
                //var errorCode = error.code
                //var errorMessage = error.message
            })
        })
    }

    render() {
        return (
            <Container className="loginFormContainer">
                <Row>
                    <Col>
                        <div className="loginForm">
                            <img src={logo} className="login-logo" alt="Bedlek.sk" />
                            <form onSubmit={this.handleSubmit}>
                                <input type="text" name="mail" placeholder="Prihlasovací e-mail" autoComplete="off" value={this.state.mail} onChange={this.handleInput} />
                                <input type="password" placeholder="Heslo" name="pass" value={this.state.pass} onChange={this.handleInput} />
                                <input type="submit" value="Prihlásiť sa" id="loginButton" />
                            </form>
                            {this.state.isFetching && <Spinner />}
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Login