import React from 'react'
import { Spinner } from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'

class MessageDetail extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            isFetching: true
        }
    }

    componentDidMount() {
        this.fetchMessage()
    }

    fetchMessage = () => {
        this.props.db.collection("messages").doc(this.props.messageId).get().then(doc => {

            this.setState({
                data: doc.data(),
                isFetching: false
            })

            //oznacim spravu ako precitanu
            if (!this.state.data.opened) this.markAsRead()
        })
    }

    markAsRead = () => {
        this.setState({
            data: { ...this.state.data, opened: true }
        })

        this.props.db.collection("messages").doc(this.props.messageId).set(this.state.data).then(() => {
            this.props.db.collection("counts").doc("unreadMessages").get().then(unreadCount => {
                this.props.db.collection("counts").doc("unreadMessages").set({ count: unreadCount.data().count - 1 })
                this.props.changeMessagesNumber(unreadCount.data().count - 1)
            })
        })
    }

    render() {
        return (
            <>
                {this.state.isFetching ? <Spinner /> :
                    <div className="messageDetail">
                        <span className="backButton" onClick={() => this.props.navigator('messages')}><IoMdArrowBack /> Späť na všetky správy</span>
                        <h3 className="senderName">{this.state.data.name}</h3>
                        <p className="sendedAt">{
                            `${this.state.data.created_at.toDate().getDate()}.
                        ${this.state.data.created_at.toDate().getMonth() + 1}.
                        ${this.state.data.created_at.toDate().getFullYear()} 
                        ${this.state.data.created_at.toDate().getHours()}:${this.state.data.created_at.toDate().getMinutes()}`
                        }</p>
                        <p className="senderMail"><span>E-mail odosielateľa:</span> <a href={"mailto:" + this.state.data.mail}>{this.state.data.mail}</a></p>
                        <p className="senderTel"><span>Telefónne číslo odosielateľa:</span> <a href={"tel:" + this.state.data.tel}>{this.state.data.tel}</a></p>
                        <p className="senderMessage">{this.state.data.message}</p>
                    </div>
                }
            </>
        )
    }
}

export default MessageDetail