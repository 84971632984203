import React from 'react';
import { Link } from 'react-scroll'

class MobileMenu extends React.Component {
    handleClose = () => {
        this.props.close()
        document.querySelector('#toggle-menu').checked = false
    }

    render() {
        return (
            <ul className="nav">
                <Link to="uvod" smooth={true} offset={-100} duration={700} onClick={this.handleClose}><li className="nav-item">Úvod</li></Link>
                <Link to="aboutus" smooth={true} offset={-100} duration={700} onClick={this.handleClose}><li className="nav-item">O nás</li></Link>
                <Link to="projects" smooth={true} offset={-100} duration={700} onClick={this.handleClose}><li className="nav-item">Projekty</li></Link>
                <Link to="contact" smooth={true} offset={-100} duration={700} onClick={this.handleClose}><li className="nav-item">Kontakt</li></Link>
            </ul>
        )
    }
}

export default MobileMenu