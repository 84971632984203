import '../styles/fonts.scss'
import React from 'react';
import Header from './Header'
import MobileHeader from './MobileHeader'
import Welcome from './Welcome'
import AboutUs from './AboutUs'
import Projects from './Projects'
import Contact from './Contact'
import SideNavigation from './SideNavigation'
import Document from './Document'
import Footer from './Footer'
import Project from './Project'
import NotFound from './NotFound'
import AllProjects from './AllProjects'
import AdminPanel from './admin/AdminPanel'
import { Container } from 'react-bootstrap'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import firebase from 'firebase'

const config = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
  databaseURL: "https://bedlek-backend-default-rtdb.europe-west1.firebasedatabase.app/"
}

firebase.initializeApp(config);
firebase.analytics()

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <Header />
          <MobileHeader />
          <HomePage />
          <Footer />
        </Route>
        <Route exact path='/ochrana-osobnych-udajov'>
          <Header />
          <MobileHeader />
          <Document id="oou" />
          <Footer />
          <div id="overlay"></div>
        </Route>
        <Route exact path='/projekty/:id' render={({ match }) =>
          <>
            <Header />
            <MobileHeader />
            <Project slug={match.params.id} />
            <Footer />
            <div id="overlay"></div>
          </>
        }>
        </Route>
        <Route exact path='/projekty'>
          <Header />
          <MobileHeader />
          <AllProjects />
          <Footer />
          <div id="overlay"></div>
        </Route>
        <Route exact path='/admin'>
          <AdminPanel />
        </Route>
        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

const HomePage = () => {
  return (
    <div id="content">
      <SideNavigation />
      <Welcome />
      <AboutUs />
      <Projects />
      <Contact />
      <div id="overlay"></div>
    </div>
  )
}

export default App;
