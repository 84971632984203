import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import firebase from 'firebase'
import Spinner from './admin/Spinner'
import { GoCheck } from 'react-icons/go'


class ContactForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            formData: {
                name: "",
                mail: "",
                tel: "",
                message: ""
            },
            checked: false,
            showMailError: false,
            showFillFieldsError: false,
            showCheckError: false,
            showForm: true,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        this.setState({
            ...this.state,
            formData: { ...this.state.formData, [event.target.name]: event.target.value }
        })
    }

    handleCheck = (event) => {
        this.setState({
            checked: event.target.checked
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.validateFields().then(() => {
            if (!this.state.showFillFieldsError && !this.state.showMailError && !this.state.showCheckError) {
                this.saveMessage().then(() => {
                    this.setState({
                        isUploading: false,
                        showForm: false
                    })
                })
            }
        })
    }

    saveMessage = () => {
        this.setState({
            isUploading: true
        })
        return new Promise(resolve => {
            firebase.firestore().collection("messages").doc().set({ ...this.state.formData, created_at: new Date(), opened: false }).then(() => {
                firebase.firestore().collection("counts").doc("unreadMessages").get().then(actualCount => {
                    firebase.firestore().collection("counts").doc("unreadMessages").set({count: actualCount.data().count + 1}).then(() => {
                        firebase.firestore().collection("counts").doc("messages").get().then(actualCount => {
                            firebase.firestore().collection("counts").doc("messages").set({count: actualCount.data().count + 1}).then(() => {
                                resolve()
                            })
                        })
                    })
                }) 
            })
        })
    }

    validateFields = () => {
        return new Promise(resolve => {
            if (Object.values(this.state.formData).includes("")) {
                this.setState({
                    showFillFieldsError: true
                })
            } else {
                this.setState({
                    showFillFieldsError: false
                })
            }

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!re.test(this.state.formData.mail.toLowerCase())) {
                this.setState({
                    showMailError: true
                })
            } else {
                this.setState({
                    showMailError: false
                })
            }

            if (!this.state.checked) {
                this.setState({
                    showCheckError: true
                })
            } else {
                this.setState({
                    showCheckError: false
                })
            }

            resolve()
        })
    }

    render() {
        return (
            <div className="contact-form">
                {this.state.isUploading ? <Spinner /> :
                    !this.state.showForm ? <p className="messageSent"><GoCheck size={16} color="green" /> Správa bola odoslaná.</p> :
                        <form onSubmit={this.handleSubmit}>
                            <label>
                                Meno
                        <input name="name" type="text" autoComplete="off" placeholder="Vaše celé meno" value={this.state.name} onChange={this.handleChange} />
                            </label>
                            <label>
                                E-mailová adresa
                        <input name="mail" type="text" autoComplete="off" placeholder="Na ktorú vás spätne môžeme kontaktovať" value={this.state.mail} onChange={this.handleChange} />
                            </label>
                            {this.state.showMailError && <p className="error-message">E-mailová, ktorú ste zadali nie je správna!</p>}
                            <label>
                                Telefónne číslo
                        <input name="tel" type="text" autoComplete="off" placeholder="Na ktorom vás zastihneme" value={this.state.tel} onChange={this.handleChange} />
                            </label>
                            <label>
                                Správa
                        <textarea rows={5} name="message" autoComplete="off" placeholder="Text vašej správy" value={this.state.mesage} onChange={this.handleChange} />
                            </label>
                            {this.state.showCheckError && <p className="error-message">Musíte súhlasiť so spracovávaním osobných údajov!</p>}
                            <div className="custom-control custom-checkbox sou-check">
                                <input type="checkbox" className="custom-control-input" id="sou" checked={this.state.checked} onChange={this.handleCheck} />
                                <label className="custom-control-label" htmlFor="sou">súhlasím so spracovávaním
                        <RouterLink to="/ochrana-osobnych-udajov" target="_blank" rel="noopener noreferrer"> osobných údajov</RouterLink>
                                </label>
                            </div>
                            {this.state.showFillFieldsError && <p className="error-message">Vyplňte prosím všetky polia!</p>}

                            <input type="submit" value="Odoslať" />
                        </form>}
            </div>
        )
    }
}

export default ContactForm