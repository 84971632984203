import React from 'react'
import ProjectRow from './ProjectRow'
import Spinner from './Spinner'

class ProjectDashboard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isFetching: false,
            projects: []
        }
    }

    componentDidMount = () => {
        this.fetchProjects()
    }

    fetchProjects = () => {
        this.setState({ isFetching: true })

        this.props.db.collection("projects").get().then(doc => {
            doc.forEach(project => {
                this.setState({
                    projects: [...this.state.projects, { id: project.id, data: project.data() }]
                })
            })

            this.setState({ isFetching: false })
        })
    }

    render() {
        return (
            <>
                <h2 className="tabHeader">Projekty</h2>
                <div className="projectsTab">
                    {this.state.isFetching ? <Spinner /> :
                        <>
                            <span className="newProjectBtn" onClick={() => this.props.navigator("new")}>+ Nový projekt</span>
                            {this.state.projects.length ?
                                <table className="dataTable">
                                    <thead>
                                        <tr>
                                            <th>Názov</th>
                                            <th>V slideri</th>
                                            <th>Pridaný</th>
                                            <th className="text-center">Akcie</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.projects.map(project =>
                                            <ProjectRow data={project.data} id={project.id} navigator={this.props.navigator} key={project.id} />
                                        )}
                                    </tbody>
                                </table>
                                :
                                <p>Nenašli sa žiadne projekty</p>         
                            }
                        </>
                    }
                </div>
            </>
        )
    }
}

export default ProjectDashboard