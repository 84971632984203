import React from 'react'
import firebase from 'firebase'
import Spinner from './admin/Spinner'
import { Container, Row, Col } from 'react-bootstrap'
import Masonry from 'react-responsive-masonry'
import { Element, Link } from 'react-scroll'
import { BsArrowDownShort } from 'react-icons/bs'
import SimpleReactLightBox, { SRLWrapper } from 'simple-react-lightbox'

class Project extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isFetching: true,
      data: null
    }
  }

  componentDidMount() {
    this.fetchProject().then(() => {
      document.title = `${this.state.data.name} | BEDLEK.SK`
      this.setState({
        isFetching: false
      })
    })
  }

  fetchProject = () => {
    return new Promise(resolve => {
      firebase.firestore().collection("projects").where("slug", "==", this.props.slug).get().then(docs => {
        docs.forEach(project => {
          this.setState({
            data: project.data(),
          })
          resolve()
          return
        })
      })
    })
  }

  render() {
    return (
      <SimpleReactLightBox>
        <div id="content" className="projectContent">
          {this.state.isFetching ?
            <Container>
              <Spinner />
            </Container>
            :
            <>
              <div className="backgroundImage"><img src={this.state.data.images[0]} className="projectBG" alt={this.state.data.name} /></div>
              <div className="projectImage">
                <Link to="projectContent" smooth={true} offset={-100} duration={700}><li>Viac o projekte <BsArrowDownShort size={24} /></li></Link>
              </div>
              <div className="contentWrapper projectHeader">
                <Element name="projectContent">
                  <Container>
                    <h1 className="projectName">{this.state.data.name}</h1>
                    <Row className="characteristics">
                      {Object.keys(this.state.data.characteristics).map(key =>
                        <Col sm={12} md={3}>
                          <h2 className="heading">{key}</h2>
                          <span className="value">{this.state.data.characteristics[key]}</span>
                        </Col>
                      )}
                    </Row>
                  </Container>
                </Element>
              </div>

              <div className="contentWrapper projectBody">
                <Container>
                  <p className="projectText">{this.state.data.content}</p>
                  <SRLWrapper options={
                    {
                      buttons:
                      {
                        showAutoplayButton: false,
                        showDownloadButton: false,
                        showThumbnailsButton: false,
                      }
                    }
                  }>
                    <Masonry columnsCount={2} gutter="5px">
                      {this.state.data.images.map(image => <img className="galleryImage" src={image} alt={this.state.data.name} key={image} />)}
                    </Masonry>
                  </SRLWrapper>
                </Container>
              </div>
            </>
          }
        </div>
      </SimpleReactLightBox>
    )
  }
}

export default Project