import React from 'react';
import { Container } from 'react-bootstrap'
import Menu from './Menu'
import logo from '../assets/logo.png'
import { Link as RouterLink } from 'react-router-dom'

export default function Header() {
    return (
        <div className="header-container" id="headerFixedMenu">
            <Container className="d-none d-md-block">
                <div className="header">
                    <RouterLink to='/'><img src={logo} className="header-logo" alt="Bedlek.sk" /></RouterLink>
                    <Menu />
                </div>
            </Container>
        </div>

    )
}