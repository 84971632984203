import React from 'react'
import { Container } from 'react-bootstrap'
import { Link as RouterLink } from 'react-router-dom'

class NotFound extends React.Component {
    componentDidMount() {
        document.title = "404 - Stránka sa nenašla | BEDLEK.SK"
        window.history.pushState('404', '404 - Stránka sa nenašla | BEDLEK.SK', '/404')
    }

    render() {
        return (
            <div id="content" className="page-not-found">
                <Container>
                    <span className="error">
                        Chyba - 404
                    </span>
                    Ľutujeme, ale stránka neexistuje alebo bola odstránená.
                    <RouterLink to="/" className="back">
                        <span>
                            {'<- Prejsť na domovskú stránku'}
                        </span>
                    </RouterLink>
                    <span className="foot">
                        BEDLEK.SK - 2021
                    </span>
                </Container>
            </div>
        )
    }
}

export default NotFound