import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import { Element } from 'react-scroll'
import firebase from 'firebase'

export default function AboutsUs() {
  const [aboutUsText, setAboutUsText] = useState('')

  useEffect(() => {
    firebase.firestore().collection("content").doc('aboutus').get().then(doc => {
      setAboutUsText(doc.data().content)
    })
  }, [])

  return (
    <Element name="aboutus">
      <div className="aboutus" id="aboutus">
        <div className="container">
          <Row>
            <Col md={12} lg={5} className="aboutus-text">
              <h2>O <b>nás</b></h2>
              <p dangerouslySetInnerHTML={{ __html: aboutUsText }} />
            </Col>
            <Col md={12} lg={7} className="aboutus-slider">
              <img src={require('../assets/vector-art1.jpg')} alt="Bedlek.sk"/>
            </Col>
          </Row>
        </div>
      </div>
    </Element>
  )
}